import Layout from "../Layout";

function Store()
{
    return (
        <Layout>

            Store
        </Layout>
    )
}


export default Store;